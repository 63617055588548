import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiRelatorio = {
    relatorioAmbiente: async (unidadeId, fusoData) => {
        const URL = `${apiLink}api/relatorio/ambiente?unidadeId=` + unidadeId;
        return await axiosInstance.post(URL, fusoData)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    relatorioCompressor: async (unidadeId, fusoData) => {
        const URL = `${apiLink}api/relatorio/compressor?unidadeId=` + unidadeId;
        return await axiosInstance.post(URL, fusoData)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    relatorioCondensacao: async (unidadeId, fusoData) => {
        const URL = `${apiLink}api/relatorio/condensacao?unidadeId=` + unidadeId;
        return await axiosInstance.post(URL, fusoData)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    relatorioCondensador: async (unidadeId, fusoData) => {
        const URL = `${apiLink}api/relatorio/condensador?unidadeId=` + unidadeId;
        return await axiosInstance.post(URL, fusoData)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    relatorioRegime: async (unidadeId, fusoData, periodo) => {
        const URL = `${apiLink}api/relatorio/regime?unidadeId=${unidadeId}&periodo=${periodo}`;
        return await axiosInstance.post(URL, fusoData)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    relatorioEquipamentos: async (unidadeId, timeZoneOffset) => {
        const config = {
			responseType: 'blob'
        };
        const URL = `${apiLink}api/relatorio/equipamentos/${unidadeId}/${timeZoneOffset}`;
        return await axiosInstance.get(URL, config)
            .then((res) => res)
            .catch(error => {throw new Error('Erro ao buscar os dados:', error)})
    },
    relatorioLeituraRegime: async (unidadeId, dataInfo) => {
        const config = {
			responseType: 'blob'
        };
        const URL = `${apiLink}api/relatorio/leituras-regimes/${unidadeId}`;
        return await axiosInstance.post(URL, dataInfo, config)
            .then((res) => res)
            .catch(error => {throw new Error(error)})
    },
    relatorioLeituraAmbiente: async (unidadeId, dataInfo) => {
        const config = {
			responseType: 'blob'
        };
        const URL = `${apiLink}api/relatorio/leituras-ambientes/${unidadeId}`;
        return await axiosInstance.post(URL, dataInfo, config)
            .then((res) => res)
            .catch(error => {throw new Error(error)})
    },
    relatorioLeituraCompressor: async (unidadeId, dataInfo) => {
        const config = {
			responseType: 'blob'
        };
        const URL = `${apiLink}api/relatorio/leituras-compressores/${unidadeId}`;
        return await axiosInstance.post(URL, dataInfo, config)
            .then((res) => res)
            .catch(error => {throw new Error(error)})
    },
    relatorioLeituraCondensador: async (unidadeId, dataInfo) => {
        const config = {
			responseType: 'blob'
        };
        const URL = `${apiLink}api/relatorio/leituras-condensadores/${unidadeId}`;
        return await axiosInstance.post(URL, dataInfo, config)
            .then((res) => res)
            .catch(error => {throw new Error(error)})
    },
    relatorioAlarmes: async (unidadeId, fusoData) => {
        const URL = `${apiLink}api/alarme/relatorio/` + unidadeId;
        return await axiosInstance.post(URL, fusoData)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    relatorioAlarmesExcel: async (unidadeId, dataInfo) => {
        const config = {
			responseType: 'blob'
        };
        const URL = `${apiLink}api/relatorio/alarmes/${unidadeId}`;
        return await axiosInstance.post(URL, dataInfo, config)
            .then((res) => res)
            .catch(error => {throw new Error(error)})
    },
    producaoDiariaExcel: async (unidadeId, dataInfo) => {
        const config = {
			responseType: 'blob'
        };
        const URL = `${apiLink}api/relatorio/producao-diaria/${unidadeId}`;
        return await axiosInstance.post(URL, dataInfo, config)
            .then((res) => res)
            .catch(error => {throw new Error(error)})
    },
    relatorioLeituraCondensacao: async (unidadeId, dataInfo) => {
        const config = {
			responseType: 'blob'
        };
        const URL = `${apiLink}api/relatorio/condensacao/${unidadeId}`;
        return await axiosInstance.post(URL, dataInfo, config)
            .then((res) => res)
            .catch(error => {throw new Error(error)})
    },
    relatorioLeituraEnergia: async (unidadeId, dataInfo) => {
        const config = {
            responseType: 'blob'
        };
        const URL = `${apiLink}api/relatorio/leitura-energia/${unidadeId}`;
        return await axiosInstance.post(URL, dataInfo, config)
            .then((res) => res)
            .catch(error => {throw new Error(error)})
    }
}

export default apiRelatorio; 