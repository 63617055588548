import { useCallback, useRef, useState } from "react";
import CardLayout from "../common/CardLayout";
import { Button } from "../ui/button";
import { DatePicker } from "../ui/datepicker";
import InputGenericRef from "../common/InputGenericRef";
import apiProducaoDiaria from "../../services/apis/apiProducaoDiaria";
import { format } from "date-fns";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import { getClientTimeZoneOffset } from "../../funcs";
import useToast from "../../hooks/useToast";

export default function CadastroDeProduto({ onSave, dataEscolhida }) {

    const { sucesso, erro } = useToast();

    const producaoTotalRef = useRef();

    const [data, setData] = useState(new Date());
    const [producaoInterno, setProducaoInterno] = useState(0);
    const [producaoExterno, setProducaoExterno] = useState(0);
    const [indav, setIndav] = useState(0);
    const [producaoTotal, setProducaoTotal] = useState();

    const handleProducaoInterno = useCallback((e) => {
        const eventValue = e.target.value
        const value = eventValue === "" ? 0 : parseInt(eventValue)

        setProducaoInterno(eventValue)
        setProducaoTotal(value + parseInt(producaoExterno) + parseInt(indav))
    }, [producaoExterno, indav])

    const handleProducaoExterno = useCallback((e) => {
        const eventValue = e.target.value
        const value = eventValue === "" ? 0 : parseInt(eventValue)

        setProducaoExterno(eventValue)
        setProducaoTotal(value + parseInt(producaoInterno) + parseInt(indav))
    }, [producaoInterno, indav])

    const handleIndav = useCallback((e) => {
        const eventValue = e.target.value
        const value = eventValue === "" ? 0 : parseInt(eventValue)

        setIndav(eventValue)
        setProducaoTotal(value + parseInt(producaoInterno) + parseInt(producaoExterno))

        setIndav(eventValue)
    }, [producaoInterno, producaoExterno]);

    const save = async () => {

        if(!producaoTotal) {
            erro("Preencha a produção total");
            return;
        }
  
        const dto = {
            producaoDiaria: {
              data: format(data, 'yyyy-MM-dd'),
              producaoMercadoInterno: parseInt(producaoInterno === "" ? 0 : producaoInterno),
              producaoMercadoExterno: parseInt(producaoExterno === "" ? 0 : producaoExterno),
              indav: parseInt(indav === "" ? 0 : indav),
              producaoTotal: parseInt(producaoTotal === "" ? 0 : producaoTotal),
              unidadeId: parseInt(getCurrentUnidadeId())
            },
            timeZoneNumber: getClientTimeZoneOffset()
        }

        apiProducaoDiaria.save(dto).then(res => {
            sucesso('Produção diária salva com sucesso')
            setProducaoInterno(0)
            setProducaoExterno(0)
            setProducaoTotal('');
            onSave(dataEscolhida);
        }).catch(error => {
            erro(error.message)
        })
    };

    return (
    <div className="flex justify-center flex-col items-center gap-2 p-2 w-full h-full mb-1">
        <div className="flex flex-col w-full">
            <CardLayout>
                <div className=" min-h-[100px] flex gap-5 items-center">
                    <div className="flex gap-5 justify-center items-end w-full flex-wrap">
                            <div>
                                <label htmlFor="" className="block text-center text-[16px]">Data</label>
                                <DatePicker
                                    id="data"
                                    name="data"
                                    className={"w-[150px] ring-1 ring-accent"}
                                    value={data}
                                    formatString="dd/MM/yyyy"
                                    onDateChange={(date) => setData(date)}
                                    initialDate={data}
                                />
                            </div>
                            <div>
                                <label htmlFor="" className="block text-center text-[16px]">Produção mercado interno</label>
                                <InputGenericRef
                                    id="producao-interno"
                                    name="producao-interno"
                                    value={producaoInterno}
                                    onChange={handleProducaoInterno}
                                    className={"w-[200x] h-[39px] m-0"}
                                    
                                />
                            </div>
                            <div>
                                <label htmlFor="" className="block text-center text-[16px]">Produção mercado externo</label>
                                <InputGenericRef
                                    id="producao-interno"
                                    name="producao-interno"
                                    value={producaoExterno}
                                    onChange={handleProducaoExterno}
                                    className={"w-[200x] h-[39px] m-0"}
                                    
                                />
                            </div>
                            <div>
                                <label htmlFor="" className="block text-center text-[16px]">INDAV</label>
                                <InputGenericRef
                                    id="producao-interno"
                                    name="producao-interno"
                                    value={indav}
                                    onChange={handleIndav}
                                    className={"w-[200x] h-[39px] m-0"}
                                    
                                />
                            </div>
                            <div>
                                <label htmlFor="" className="block text-center text-[16px]">Produção total</label>
                                <InputGenericRef
                                    id="producao-interno"
                                    name="producao-interno"
                                    value={producaoTotal}
                                    onChange={(e) => setProducaoTotal(e.target.value)}
                                    className={"w-[200x] h-[39px] m-0"}
                                    ref={producaoTotalRef}
                                />
                            </div>
                            <div className="h-full flex items-end">
                                <Button onClick={() => save()}>
                                    Salvar
                                </Button>
                            </div>
                    </div>
                </div>
            </CardLayout>
        </div>
    </div>
    )
}