import { useEffect, useState } from "react";
import CardLayout from "../common/CardLayout";
import NoDataAlert from "../common/returns/NoDataAlert";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import { GaugeChart } from "./GaugeChart";
import DateIntervalPopOver from "./DateIntervalPopOver";
import { FaSpinner } from "react-icons/fa";
import { getClientTimeZoneOffset } from "../../funcs";
import apiRelatorio from "../../services/apis/apiRelatorio";

export default function Condensacao({dadosCondensacao, unidade, erro}) {
    const [condensacao, setCondensacao] = useState();
    const [tbu, setTbu] = useState();
    const [isLoadingRelatorio, setIsLoadingRelatorio] = useState(false);
    const [linhaLiquido, setLinhaLiquido] = useState();
        
    useEffect(() => {
        const novaCondensacao = dadosCondensacao?.condensacaoInfo?.condensacao || 0;
        const condensacaoComLeituras = {
            ...novaCondensacao,
            leiturasRegimes: dadosCondensacao?.condensacaoInfo?.leituraCondensacao
        }

        setCondensacao(condensacaoComLeituras);
        setTbu(dadosCondensacao?.ultimaLeituraTbu);
        setLinhaLiquido(dadosCondensacao?.ultimaLeituraLinhaLiquido || 0);


    }, [dadosCondensacao]);


    const gerarRelatorio = async (dataInicio, dataFim) => {
        try {
            setIsLoadingRelatorio(true);
            const timeZoneOffset = getClientTimeZoneOffset();

            const dataInfo = {
                dataInicio: dataInicio,
                dataFim: dataFim,
                TimeZoneNumber: timeZoneOffset
            }

            const dataInicioFormatada = dataInfo.dataInicio.toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replaceAll(",", "_").replaceAll(" ", "");

            const dataFinalFormatada = dataInfo.dataFim.toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replaceAll(",", "_").replaceAll(" ", "");

            //se data inicio for maior que data final
            if (dataInfo.dataInicio > dataInfo.dataFim) {
                erro('Data de início precisa ser maior que data final');
                return;
            }

            const response = await apiRelatorio.relatorioLeituraCondensacao(unidade.id, dataInfo);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `Leituras_Condensacao_${unidade.nome}_${dataInicioFormatada}-${dataFinalFormatada}.xlsx`);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            erro(error.message);
        } finally {
            setIsLoadingRelatorio(false);
        }
    }



    return (
        !condensacao ? <NoDataAlert /> :  
        <CardLayout>
            <Accordion
                type="single"
                defaultValue={`item-condensacao`}
                collapsible={true}
            >
                <AccordionItem
                    value={`item-condensacao`}
                >
                    <div className="font-semibold">
                        <AccordionTrigger>
                            <div className="w-full flex items-center justify-center text-sm lg:text-2xl drop-shadow-1xl">
                                    <div
                                        className={`uppercase`}
                                    >
                                        <p>
                                            <span className="capitalize">
                                                {
                                                    condensacao.nome
                                                }
                                            </span>
                                        </p>
                                    </div>
                            </div>
                        </AccordionTrigger>
                    </div>
                    {
                        !condensacao.leiturasRegimes ?
                            <NoDataAlert /> :
                            <AccordionContent>
                                <div className="flex flex-col gap-2 px-4 items-center justify-center">
                                    <div className="mr-[25px]">
                                        <DateIntervalPopOver onGerarRelatorio={gerarRelatorio} disabled={isLoadingRelatorio}>
                                            {
                                                isLoadingRelatorio ?
                                                    <div className="animate-spin">
                                                        <FaSpinner size={18} />
                                                    </div>
                                                    :
                                                    'Gerar Relatório'
                                            }
                                        </DateIntervalPopOver>
                                    </div>
                                    <div className="flex flex-wrap justify-center">
                                        <div>
                                            <GaugeChart valor={condensacao.leiturasRegimes.eficienciaCondensadores} label={"Eficiência dos condensadores"} />
                                        </div>
                                        <div className="flex flex-col min-h-full justify-around items-center">
                                            <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[30rem] 3xl:px-4 my-2">
                                                <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                Pressão de referência:
                                                </p>
                                                <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                    {condensacao ? condensacao?.leiturasRegimes?.pressaoReferenciaBar?.toLocaleString(
                                                        'pt-BR',
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) + " bar"
                                                    : "Sem dados"
                                                    }
                                                </span>
                                            </div>
                                            <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[30rem] 3xl:px-4 my-2">
                                                <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                    Líquido:
                                                </p>
                                                <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                    {linhaLiquido ? linhaLiquido.temperatura?.toLocaleString(
                                                        'pt-BR',
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) + " ºC"
                                                    : "Sem dados"
                                                    }
                                                </span>
                                            </div>
                                            <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[30rem] 3xl:px-4 my-2">
                                                <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                    TBU:
                                                </p>
                                                <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                    {tbu ? tbu.tbuCalculado?.toLocaleString(
                                                        'pt-BR',
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) ||  + " ºC"
                                                    : "Sem dados"
                                                }
                                                </span>
                                            </div>
                                            <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[30rem] 3xl:px-4 my-2">
                                                <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                    Incondensáveis:
                                                </p>
                                                <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                    {condensacao.leiturasRegimes?.incondensaveisBar?.toLocaleString(
                                                        'pt-BR',
                                                        {
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) + " bar"}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex text-center justify-center border-2 items-center border-red-600 rounded-full h-8 w-[550px] 3xl:px-4 my-2">
                                        <p className="text-sm 3xl:text-lg font-bold px-4 text-red-600">
                                            Desperdício mensal de energia:
                                        </p>
                                        <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                            {condensacao.leiturasRegimes?.desperdicio?.toLocaleString(
                                                'pt-BR',
                                                {
                                                    maximumFractionDigits: 2,
                                                }
                                            ) + " kWh"}
                                        </span>
                                    </div>
                                </div>
                            </AccordionContent>
                    }
                </AccordionItem>
            </Accordion>
        </CardLayout>
    );
}