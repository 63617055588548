import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiProducaoDiaria = {
    getByDate: async (unidadeId, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/producao-diaria/producoes-mes/${unidadeId}`;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },
    save: async (requestDto) => {
        const URL = `${apiLink}api/producao-diaria`;
        return await axiosInstance.post(URL, requestDto)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error.message);
                throw new Error(error.response.data);
            })
    },
    getByYear: async (unidadeId, year) => {
        const URL = `${apiLink}api/producao-diaria/producoes-ano/${unidadeId}/${year}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },
    getIndicadorMensal: async (unidadeId, data) => {
        const config = { headers: { 'Content-Type': 'application/json' } };
        const URL = `${apiLink}api/producao-diaria/indicador-mensal/${unidadeId}`;
        return await axiosInstance.post(URL, data, config)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);
            })
    },
    getUltimaProducaoDiaria: async (unidadeId) => {
        const URL = `${apiLink}api/producao-diaria/ultima-producao-diaria/${unidadeId}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },
    indicadoresByYear: async (unidadeId, dataInfo) => {
        const URL = `${apiLink}api/producao-diaria/indicadores-mensais-ano/${unidadeId}`;
        return await axiosInstance.post(URL, dataInfo)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    }


}

export default apiProducaoDiaria; 


