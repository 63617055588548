import Chart from 'react-apexcharts';
import NoDataAlert from '../common/returns/NoDataAlert';

const ChartBarDefault = ({ chartDataProps, typeChart="Produção" }) => {
  function getDatesOfMonth(year, month) {
    const dates = [];
    const date = new Date(year, month, 1);

    while (date.getMonth() === month) {
      dates.push(date.getDate().toString().padStart(2, '0'));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  if (!chartDataProps || chartDataProps.length === 0) return <NoDataAlert />;

  const year = new Date(chartDataProps[0].data).getFullYear();
  const month = new Date(chartDataProps[0].data).getMonth();
  const categories = getDatesOfMonth(year, month);

  const dataMap = categories.reduce((map, day) => {
    map[day] = 0;
    return map;
  }, {});

  chartDataProps.forEach((item) => {
	  const utcDate = new Date(item.data);
	  const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);
	  const day = localDate.toLocaleDateString('pt-BR', { day: '2-digit' });
	  dataMap[day] = item;
  });

  const seriesData = categories.map((day) => dataMap[day]?.producaoTotal || 0);
  const seriesDataProducaoInterna = categories.map((day) => dataMap[day]?.producaoMercadoInterno || 0);
  const seriesDataProducaoExterna = categories.map((day) => dataMap[day]?.producaoMercadoExterno || 0);
  const seriesDataProducaoIndicador = categories.map((day) => dataMap[day]?.indicadorDiario || 0);
  const seriesDataIndav = categories.map((day) => dataMap[day]?.indav || 0);

  
  const options = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
      borderColor: '#40475D',
      strokeDashArray: 0,
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'last',
        columnWidth: '85%',
        horizontal: false,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
      textAnchor: 'middle',
      style: {
        colors: ['#FFF'],
        fontSize: '14px',
      },
      offsetY: 0,
      offsetX: 0,
      dropShadow: {
        enabled: true,
        left: 2,
        top: 2,
        opacity: 0.5,
      },
    },
    stroke: {
      width: 1,
      colors: ['#33333380'],
    },
    xaxis: {
      categories,
      position: 'bottom',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
        color: '#40475D',
      },
      labels: {
        show: true,
        style: {
          colors: '#40475D',
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      reversed: false,
      opposite: false,
      floating: false,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
        color: '#40475D',
      },
      labels: {
        show: true,
        style: {
          colors: '#40475D',
          fontSize: '12px',
          fontStyle: 'bold',
        },
        formatter: function (value) {
          var formattedValue = new Intl.NumberFormat('pt-BR').format(value.toFixed(typeChart === 'Indicador' ? 0 : 2));
          return formattedValue;
        },
      },
    },
    tooltip: {
      theme: 'dark',
      y: {
        show: true,
        formatter: function (value) {
          var formattedValue = typeChart === 'Indicador' ? new Intl.NumberFormat('pt-BR').format(value.toFixed(0)) + '  kWh/ton' : 
          new Intl.NumberFormat('pt-BR').format(value.toFixed(2))  + ' kg';
          return formattedValue;
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        type: 'radial',
        shadeIntensity: 1,
        gradientToColors: ['#6078ea'],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [],
        colorStops: [],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'top',
      horizontalAlign: 'left',
      fontStyle: 'bold',
      fontColor: 'white',
      fontSize: '16px',
      labels: {
        colors: 'white',
        useSeriesColors: false,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  };

  let series;

  if (typeChart === 'Indicador') {
    series = [
      {
        name: 'Indicador Diário',
        data: seriesDataProducaoIndicador,
      },
    ];
  } else if(typeChart === 'Produção') {
    series = [
      {
        name: 'Produção Diária',
        data: seriesData,
      },
      {
        name: 'Produção Mercado Interno',
        data: seriesDataProducaoInterna,
      },
      {
        name: 'Produção Mercado Externo',
        data: seriesDataProducaoExterna,
      },
      {
        name: 'INDAV',
        data: seriesDataIndav,

      }
    ];

  }

  return (
    <div className="bg-transparent w-full h-full px-2 text-center">
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={320}
      />
    </div>
  );
};

export default ChartBarDefault;
