export default function Simulacao({
    potenciaRealCalculada,
    capacidadeRealCalculada,
}) {
    return (
        <div className="w-full bg-[#0b101d] text-center p-5 rounded-t-md border-b">
            <p>Capacidade Compressor: {capacidadeRealCalculada?.toLocaleString(
                'pt-BR',
                {
                    maximumFractionDigits: 2,
                }
            )}</p>
            <p>Potência Compressor: {potenciaRealCalculada?.toLocaleString(
                'pt-BR',
                {
                    maximumFractionDigits: 2,
                }
            )}</p>
        </div>
    )
}