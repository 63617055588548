import { TbSum } from "react-icons/tb"
import CardLayout from "../common/CardLayout"
import TitleCardSimple from "../common/TitleCardSimple"
import { createElement } from "react"

export default function ChartCard({ chartDataProps, type="diário", total="producaoTotal", chart, typeChart="Produção" }) {

    return (
        <>
            <div className=" text-white">
                {/* <p> fazer o fetch para a data: {formattedDate}.</p> */}
            </div>
            <div className="flex justify-center flex-col items-center gap-2 p-2 w-full h-full">
                <div className="flex flex-col w-full">
                    <CardLayout>
                        <div className="flex flex-col justify-center items-center">
                            <TitleCardSimple>
                                {typeChart}{' '}
                                <span className="text-accent">
									(
								</span>
								{type}
								<span className="text-accent">
									)
								</span>
                            </TitleCardSimple>
                        </div>
                        {
                            total !=="none" &&
                            <div className="flex justify-center items-center flex-coltext-white text-sm lg:text-lg">
                                <p className="py-2 flex items-center px-2 lg:text-lg">
                                    Total
                                    <TbSum className="h-5 w-5 pl-1" />
                                </p>
                                <p className="drop-shadow-1xl text-lg lg:text-2xl font-bold bg-gradient-to-r from-accent via-indigo-500 to-accent inline-block text-transparent bg-clip-text">
                                    {chartDataProps?.reduce((a, b) => a + b[total], 0)?.toLocaleString(
                                        'pt-BR',
                                        {
                                            maximumFractionDigits: 2,
                                        }
                                    )}{' '}
                                    kg
                                </p>
                            </div>
                        }
                        
                        <div className="flex justify-center items-center">
                                {
                                    createElement(chart, {chartDataProps, typeChart})
                                }
                                
                        </div>
                    </CardLayout>
                </div>
            </div>
        </>
    )
}
