import React, { useCallback, useEffect, useState } from 'react';
import CardLayout from '../../common/CardLayout';
import { GaugeChart } from './charts/GaugeChart';
import { FaArrowUpLong, FaArrowDownLong } from 'react-icons/fa6';
import apiUltimaLeituraCondensacao from '../../../services/apis/Leituras/apiUltimaLeituraCondensacao';
import SignalService from '../../../services/SignalService';
import { apiLink } from '../../../constants/env';
import NoDataAlert from '../../common/returns/NoDataAlert';
import isNotUndefined from '../../../funcs/isNotUndefined';
import compareObjects from '../../../funcs/compareObjects';
import apiLeituraLinhaLiquido from '../../../services/apis/Leituras/apiLeituraLinhaLiquido';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';
import apiTbu from '../../../services/apis/apiTbu';

const Condensacao = ({ condensacao, capacidadeCondensadores }) => {
	const unidadeId = getCurrentUnidadeId();
	const [leituraCondensacao, setLeituraCondensacao] = useState();
	const [hubConnection, setHubConnection] = useState();
	const [dadoAtualizado, setDadoAtualizado] = useState();
	const [tbu, setTbu] = useState(0);
	const [key, setKey] = useState(0);
	const [linhaLiquido, setLinhaLiquido] = useState();
	const [hubConnectionTbu, setHubConnectionTbu] = useState();
	const [hubConnectionLinhaLiquido, setHubConnectionLinhaLiquido] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await apiLeituraLinhaLiquido.getUltimaLeitura(unidadeId);
				setLinhaLiquido(res.data);
			} catch (error) {
				console.error(error.message);
			}
		};
		fetchData();
	}, [unidadeId]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await apiTbu.ultimaLeitura(unidadeId);
				if (res.status === 200) {
					setTbu(res.data);
				} else {
					console.warn('Nenhuma leitura de TBU encontrada.');
				}

			} catch (error) {
				
			}
		}
		fetchData();
	},[unidadeId]);

	
	//ATUALIZAÇÃO EM TEMPO REAL
	useEffect(() => {
		setHubConnectionTbu(
			SignalService.startConnection(`${apiLink}LeituraTbuHub`, unidadeId)
		);
	}, [unidadeId]);

	useEffect(() => {
		if (hubConnectionTbu) {
			hubConnectionTbu
				.start()
				.then(() => {
					return hubConnectionTbu.invoke('Connect', unidadeId);
				})
				.then((result) => {
					hubConnectionTbu.on(
						'AtualizarDadosTbu',
						(message) => {
							setTbu(message);
						}
					);
				})
				.catch((e) => console.log('Conexão falhou. ', e));
		}
	}, [hubConnectionTbu, unidadeId]);

	useEffect(() => {
		setHubConnectionLinhaLiquido(
			SignalService.startConnection(`${apiLink}LeituraLinhaLiquidoHub`, unidadeId)
		);
	}, [unidadeId]);

	useEffect(() => {
		if (hubConnectionLinhaLiquido) {
			hubConnectionLinhaLiquido
				.start()
				.then(() => {
					return hubConnectionLinhaLiquido.invoke('Connect', unidadeId);
				})
				.then((result) => {
					hubConnectionLinhaLiquido.on(
						'AtualizarDadosLinhaLiquido',
						(message) => {
							setLinhaLiquido(message);
						}
					);
				})
				.catch((e) => console.log('Conexão falhou. ', e));
		}
	}, [hubConnectionLinhaLiquido, unidadeId]);

	const atualizarGauge = useCallback(() => {
		setKey(key + 1);
	}, [key]);

	useEffect(() => {
		condensacao?.id !== undefined &&
			apiUltimaLeituraCondensacao
				.fetchApi(condensacao?.id)
				.then((res) => {
					setLeituraCondensacao(res.data);
				})
				.catch((error) => {
					console.error(error.message);
				});
	}, [condensacao]);

	//ATUALIZAÇÃO EM TEMPO REAL
	useEffect(() => {
		setHubConnection(
			SignalService.startConnection(
				`${apiLink}LeituraCondensacaoHub`, unidadeId
			)
		);
	}, [unidadeId]);

	useEffect(() => {
		if (hubConnection) {
			hubConnection
				.start()
				.then(() => {
					return hubConnection.invoke('Connect', unidadeId);
				})
				.then((result) => {
					hubConnection.on(
						'AtualizarDadosCondensacao',
						(message) => {
							setDadoAtualizado(message);
						}
					);
				})
				.catch((e) => console.log('Conexão falhou. ', e));
		}
	}, [hubConnection, key, unidadeId]);

	useEffect(() => {
		if (
			!compareObjects(dadoAtualizado, leituraCondensacao) &&
			dadoAtualizado !== undefined
		) {
			setLeituraCondensacao(dadoAtualizado);
			atualizarGauge();
		}
	}, [dadoAtualizado, atualizarGauge, leituraCondensacao]);


	useEffect(() => {
		console.warn(leituraCondensacao);
	},[leituraCondensacao]);

	return (
		<>
			<CardLayout>
				<div className="flex flex-col gap-y-4 lg:pb-4 pb-2">
					<div className="text-center pt-2 text-lg lg:text-2xl  font-semibold drop-shadow-1xl text-accent uppercase">
						CONDENSAÇÃO
					</div>
					<div className="flex flex-row justify-around items-center pt-1">
						<div className="text-sm md:text-lg 2xl:text-2xl font-bold drop-shadow-1xl">
							Capacidade
						</div>
						<div className="">
							<span className="text-lg md:text-2xl font-bold drop-shadow-1xl bg-gradient-to-r from-accent via-indigo-400 to-accent inline-block text-transparent bg-clip-text">
								{ 
									capacidadeCondensadores ? capacidadeCondensadores?.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)
									+ ' Kcal/ h'
									: 'Carregando...'
								}
							</span>
						</div>
					</div>
				</div>
				<div className="flex flex-col  md:flex-row text-center justify-center">
					<div className="flex flex-col w-full md:w-1/3">
						<div className="flex justify-center items-center">
							{leituraCondensacao !== undefined ? (
								<GaugeChart
									key={key}
									regime={condensacao}
									pressaoAtual={
										leituraCondensacao.pressaoAtual
									}
								/>
							) : (
								<NoDataAlert />
							)}
						</div>
						<div className="flex w-full justify-around items-center text-secondary text-xs p-4">
							<p className="text-secondary text-lg">
								{isNotUndefined(
									condensacao?.rangeMin?.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)
								)}
								{' Bar'}
							</p>
							<p className="text-secondary text-lg">
								{isNotUndefined(
									condensacao?.rangeMax
								)}
								{' Bar'}
							</p>
						</div>
						<div className="py-2 flex justify-center items-center lg:justify-between flex-col text-secondary font-normal text-left">
							<div className="text-secondary inline-flex text-sm 2xl:text-lg p-1">
								<FaArrowUpLong className="w-4 h-4 text-accent 2xl:w-5 2xl:h-5" />
								Max. registrada:{' '}
								<span className="font-semibold drop-shadow-1xl text-white px-1">
									{isNotUndefined(
										leituraCondensacao?.pressaoMaxima?.toLocaleString(
											'pt-BR',
											{
												maximumFractionDigits: 2,
											}
										)
									)}
									{' Bar'}
								</span>
							</div>
							<div className="text-secondary inline-flex text-sm 2xl:text-lg p-1">
								<FaArrowDownLong className="w-4 h-4 text-accent 2xl:w-5 2xl:h-5" />
								Min. registrada:
								<span className="font-semibold drop-shadow-1xl text-white px-1">
									{isNotUndefined(
										leituraCondensacao?.pressaoMinima?.toLocaleString(
											'pt-BR',
											{
												maximumFractionDigits: 2,
											}
										)
									)}
									{' Bar'}
								</span>
							</div>
						</div>
					</div>
					<div className="flex flex-col justify-center items-center md:w-1/2 lg:mx-4 gap-y-2 md:gap-y-4 font-semibold">
						<div className="flex justify-between items-center  w-full border-2 border-accent rounded-full px-2 py-[2px] 2xl:px-8">
							<p className="md:text-lg text-sm text-secondary">
							Pressão de referência:
							</p>
							<span className="drop-shadow-1xl text-white md:text-lg text-sm">
								{isNotUndefined(
									leituraCondensacao?.pressaoReferenciaBar?.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)
								)}
								{' bar'}
							</span>
						</div>
						{/* <div className="flex justify-between items-center  w-full border-2 border-accent rounded-full px-2 py-[2px] 2xl:px-8">
							<p className="md:text-lg text-sm text-secondary">
								Referência:
							</p>
							<span className="drop-shadow-1xl text-white md:text-lg text-sm">
								{isNotUndefined(
									leituraCondensacao?.pressaoReferenciaBar?.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)
								)}
								{' Bar'}
							</span>
						</div> */}
						<div className="flex justify-between items-center  w-full border-2 border-accent rounded-full px-2 py-[2px] 2xl:px-8">
							<p className="md:text-lg text-sm text-secondary">
							Temp. de condensação:
							</p>
							<span className="drop-shadow-1xl text-white md:text-lg text-sm">
								{isNotUndefined(
									leituraCondensacao?.temperaturaCondensacao?.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)
								)}
								{' °C'}
							</span>
						</div>
						<div className="flex justify-between items-center  w-full border-2 border-accent rounded-full px-2 py-[2px] 2xl:px-8">
							<p className="md:text-lg text-sm text-secondary">
								Temp. Nh3 reservatório:
							</p>
							<span className="drop-shadow-1xl text-white md:text-lg text-sm">
								{isNotUndefined(
									linhaLiquido?.temperatura?.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)
								)}
								{' ºC'}
							</span>
						</div>
						<div className="flex justify-between items-center  w-full border-2 border-accent rounded-full px-2 py-[2px] 2xl:px-8">
							<p className="md:text-lg text-sm text-secondary">
								TBU:
							</p>
							<span className="drop-shadow-1xl text-white md:text-lg text-sm">
								{isNotUndefined(
									tbu?.tbuCalculado?.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)
								)}
								{' °C'}
							</span>
						</div>
						<div className="flex justify-between items-center  w-full border-2 border-accent rounded-full px-2 py-[2px] 2xl:px-8">
							<p className="md:text-lg text-sm text-secondary">
								Eficiência dos condensadores:
							</p>
							<span className="drop-shadow-1xl text-white md:text-lg text-sm">
								{isNotUndefined(
									leituraCondensacao?.eficienciaCondensadores?.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)
								)}
								{' %'}
							</span>
						</div>
						<div className="flex justify-between items-center 2 w-full border-2 border-accent rounded-full px-2 py-[2px] 2xl:px-8">
							<p className="md:text-lg text-sm text-secondary">
								Incondensaveis:
							</p>
							<div className='flex justify-end w-full gap-x-5'>
								{/* <span className="drop-shadow-1xl text-white md:text-lg text-sm whitespace-nowrap">
									{isNotUndefined(
										leituraCondensacao?.incondensaveisPctg?.toLocaleString(
											'pt-BR',
											{
												maximumFractionDigits: 2,
											}
										)
									)}
									{' % '}
								</span> */}
								<span className="drop-shadow-1xl text-white md:text-lg text-sm whitespace-nowrap">
									{isNotUndefined(
										(leituraCondensacao?.pressaoAtual - linhaLiquido?.pressaoConvertida)?.toLocaleString(
											'pt-BR',
											{
												maximumFractionDigits: 2,
											}
										)
									)}
									{' bar'}
								</span>
							</div>

						</div>
					</div>
				</div>
			</CardLayout>
		</>
	);
};

export default Condensacao;
