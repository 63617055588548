import { useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { ButtonModal } from "../ui/button-modal";
import { DateTimePicker } from "../ui/date-time-picker";

export default function DateIntervalPopOver({ children, onGerarRelatorio, disabled }) {

    const [popoverOpen, setPopoverOpen] = useState(false);
    const [tempSelectedDateDe, setTempSelectedDateDe] = useState(new Date());
    const [tempSelectedDateAte, setTempSelectedDateAte] = useState(new Date());

    const handleDateDeChange = (date) => {
        setTempSelectedDateDe(date);
    }

    const handleDateAteChange = (date) => {
        setTempSelectedDateAte(date);
    }

    const gerarRelatorio = async () => {
       onGerarRelatorio(tempSelectedDateDe, tempSelectedDateAte);
    }

    return (
        <Popover open={disabled ? false : popoverOpen} onOpenChange={setPopoverOpen} disabled={true}>
            <PopoverTrigger className="flex justify-center items-center">
                <ButtonModal >
                    {children}
                </ButtonModal>
            </PopoverTrigger>
            <PopoverContent>
                <div className="w-full h-full flex justify-center items-start flex-col">
                    <p>De</p>
                    <div className="border-b border-b-accent mb-3">
                        <DateTimePicker 
                        selectedDate={tempSelectedDateDe}
                        onDateChange={handleDateDeChange}
                        />
                    </div>
                    <p>Até</p>
                    <div className="border-b border-b-accent mb-5">
                    <DateTimePicker 
                        selectedDate={tempSelectedDateAte}
                        onDateChange={handleDateAteChange}
                        />
                    </div>
                    <div className="w-full flex justify-center">
                        <Button onClick={() => {gerarRelatorio();setPopoverOpen(false);}}>
                            Gerar relatório
                        </Button>
                    </div>
                </div>
            </PopoverContent>
        </Popover>

    )
}