import ListRegimes from '../../../components/monitoramento/geral/ListRegimes';
import ListAlarms from '../../../components/monitoramento/geral/ListAlarms';
import Energia from '../../../components/monitoramento/geral/Energia';
import ListAmbientes from '../../../components/monitoramento/geral/ListAmbientes';
import ListChillers from '../../../components/monitoramento/geral/ListChillers';
import Condensacao from '../../../components/monitoramento/geral/Condensacao';
import ListCompressores from '../../../components/monitoramento/geral/ListCompressores';
import ListCondensadores from '../../../components/monitoramento/geral/ListCondesadores';
import { useEffect, useState } from 'react';
import apiRegimes from '../../../services/apis/apiRegimes';
import { useNavigate } from 'react-router-dom';
import apiLeituraRegime from '../../../services/apis/Leituras/apiUltimaLeituraRegime';
import SignalService from '../../../services/SignalService';
import { apiLink } from '../../../constants/env';
import apiAlarme from '../../../services/apis/apiAlarme';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';
import { colorsPallete } from '../../../constants';
export default function MonitoramentoGeral() {
	const navigate = useNavigate();
	const [listaRegimes, setListaRegimes] = useState([]);
	const [hubConnection, setHubConnection] = useState();
	const [dadoAtualizado, setDadoAtualizado] = useState();
	const [leituraAtualListaRegime, setLeituraAtualListaRegime] = useState(
		[]
	);
	const [condensacao, setCondensacao] = useState({});
	const [capacidadeCondensadores, setCapacidadeCondensadores] = useState([]);
	const [listaAlarmes, setListaALarmes] = useState([]);
	const [hubConnectionAlarmes, setHubConnectionAlarmes] = useState();
	const [dadoAtualizadoAlarmes, setDadoAtualizadoAlarmes] = useState();
	const unidadeId = getCurrentUnidadeId();

	useEffect(() => {
		apiAlarme
			.fetchApi(getCurrentUnidadeId())
			.then((res) => {
				setListaALarmes(res.data);
			})
			.catch((error) => {
				console.error(error.message);
			});
	}, []);

	//ATUALIZAÇÃO EM TEMPO REAL
	useEffect(() => {
		setHubConnectionAlarmes(
			SignalService.startConnection(`${apiLink}AlarmesHub`, unidadeId)
		);
	}, []);

	useEffect(() => {
		if (hubConnectionAlarmes) {
			hubConnectionAlarmes
				.start()
				.then(() => {
					return hubConnectionAlarmes.invoke('Connect', unidadeId);
				})
				.then((result) => {
					hubConnectionAlarmes.on(
						'AtualizarAlarmes',
						(message) => {
							setDadoAtualizadoAlarmes(message);
						}
					);
				})
				.catch((e) => console.log('Conexão falhou. ', e));
		}
	}, [hubConnectionAlarmes, unidadeId]);

	useEffect(() => {
		if (dadoAtualizadoAlarmes) {
			setListaALarmes((prevLista) => {
				const aux = [...prevLista];
				const index = aux.findIndex(
					(elemento) => elemento.id === dadoAtualizadoAlarmes.id
				);
				const jaExiste = index !== -1;

				if (dadoAtualizadoAlarmes.ativo) {
					if (jaExiste) {
						aux[index] = dadoAtualizadoAlarmes;
					} else {
						aux.push(dadoAtualizadoAlarmes);
					}
				} else {
					if (jaExiste) {
						aux.splice(index, 1);
					}
				}

				return aux;
			});
		}
	}, [dadoAtualizadoAlarmes]);

	// GET REGIMES

	useEffect(() => {
		if (unidadeId === null) {
			navigate('/escolha-unidade');
		}
	}, [navigate]);
	
	useEffect(() => {
		const getRegimes = async () => {
			try {
				//GET REGIMES E CONDENSACAO
				const res = await apiRegimes.getByUnidadeId(unidadeId);
				const condensacao = res.data.find(regime => regime.tipoRegime === 'Condensação');
				const regimes = res.data.filter(regime => regime.tipoRegime !== 'Condensação');
				const regimesWithColors = regimes.map((regime, index) => {
					return {
						...regime,
						color: colorsPallete[index].class,
						RegimeColor: colorsPallete[index].borderClass,
					};
				});

				setListaRegimes(regimesWithColors);
				setCondensacao(condensacao);

				const leituras = regimes.map(({leiturasRegimes}) => leiturasRegimes[0]);

				setLeituraAtualListaRegime(leituras);

			} catch (error) {
				console.error(error.message);
			}
		};
		getRegimes();
	},[])

	//ATUALIZAÇÃO EM TEMPO REAL
	useEffect(() => {
		setHubConnection(
			SignalService.startConnection(`${apiLink}LeituraRegimeHub`, unidadeId)
		);
	}, [unidadeId]);

	//REGIME
	useEffect(() => {
		if (hubConnection) {
			hubConnection
				.start()
				.then(() => {
					return hubConnection.invoke('Connect', unidadeId);
				})
				.then((result) => {
					hubConnection.on(
						'AtualizarDadosRegime',
						(message) => {
							setDadoAtualizado(message);
						}
					);
				})
				.catch((e) => console.log('Conexão falhou. ', e));
		}
	}, [hubConnection, unidadeId]);

	//ATUALIZAR DADOS REGIME
	useEffect(() => {
		if (dadoAtualizado) {
			try {
				const index = leituraAtualListaRegime.findIndex(
					(elemento) => {
						return (
							elemento.regimeId ===
							dadoAtualizado.regimeId
						);
					}
				);
				
				
				let arrayAux = [...leituraAtualListaRegime];
				
				if(index === -1) {
					arrayAux.push(dadoAtualizado);
				} else {
					arrayAux[index] = dadoAtualizado;
				}
				setLeituraAtualListaRegime([...arrayAux]);
				setDadoAtualizado(null);
			} catch (error) {
				console.error(
					'Monitoramento Geral~ Erro ao atualizar Regime:',
					error
				);
			}
		}
	}, [dadoAtualizado, leituraAtualListaRegime]);

	return (
		<>
			<div className="flex flex-col justify-center items-center w-full h-full pb-16 gap-4">
				<div className="flex w-full flex-wrap lg:flex-nowrap gap-4">
					<ListAlarms listaAlarmes={listaAlarmes}/>
				</div>
				<div className="flex w-full flex-wrap lg:flex-nowrap gap-2">
					<ListRegimes
						listaRegimes={listaRegimes}
						leituraAtualListaRegime={
							leituraAtualListaRegime
						}
					/>
				</div>
				<div className="flex w-full flex-wrap lg:flex-nowrap gap-4">
					<Condensacao condensacao={condensacao} capacidadeCondensadores={capacidadeCondensadores}/>
					<Energia />
				</div>
				<div className="flex w-full flex-wrap lg:flex-nowrap -mt-2">
					<ListAmbientes listaRegimes={listaRegimes} />
				</div>
				<div className="flex w-full flex-wrap lg:flex-nowrap -mt-4">
					<ListChillers listaRegimes={listaRegimes} />
				</div>
				<div className="flex w-full flex-wrap lg:flex-nowrap gap-4">
					<ListCompressores
						listaRegimes={listaRegimes.filter(regime => regime.tipoRegime !== 'Condensação')}
						leiturasRegime={leituraAtualListaRegime}
						listaAlarmes={listaAlarmes.filter(alarme => alarme.tipoEquipamento === 'Compressor')}
					/>
				</div>
				<ListCondensadores 
					condensacao={condensacao}  
					setCapacidadeCondensadores={setCapacidadeCondensadores} 
					alarmes={listaAlarmes.filter(({tipoEquipamento}) => (tipoEquipamento === 'Ventilador' || tipoEquipamento === 'Bomba' || tipoEquipamento === 'Condensador'))}
				/>
			</div>
		</>
	);
}
