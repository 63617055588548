import { useCallback, useEffect, useState } from "react";
import DatePickerGetData from "../../components/common/DatePickerGetData";
import apiProducaoDiaria from "../../services/apis/apiProducaoDiaria";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import ChartCard from "../../components/producao-diaria/ChartCard";
import CadastroDeProduto from "../../components/producao-diaria/CadastroDeProducao";
import ProducaoDiariaTable from "../../components/producao-diaria/ProducaoDiariaTable";
import apiRelatorio from "../../services/apis/apiRelatorio";
import useToast from "../../hooks/useToast";
import { getClientTimeZoneOffset } from "../../funcs";
import apiUnidade from "../../services/apis/apiUnidade";
import ChartBarDefault from "../../components/producao-diaria/ChartBarDefault";
import { format } from "date-fns";
import ChartBarAnual from "../../components/producao-diaria/ChartBarAnual";

export default function ProducaoDiaria() {
    
    const [producaoDiaria, setProducaoDiaria] = useState([]);
    const [isLoadingRelatorio, setIsLoadingRelatorio] = useState(false);
    const [dataEscolhida, setDataEscolhida] = useState(null);
    const unidadeId = getCurrentUnidadeId();
    const [unidade, setUnidade] = useState({});
    const [producoesAnuais, setProducoesAnuais] = useState([]);
    const [indicadoresAnuais, setIndicadoresAnuais] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const unidade = (await apiUnidade.getById(unidadeId)).data;
                setUnidade(unidade);
            } catch (error) {
                console.error("Erro ao buscar unidade");
            }
        }
        fetchData();
    }, [unidadeId]);

    const { erro } = useToast();
    
    const onSearch = useCallback(async (date) => {
        try {
            const dataFormatada = date?.toLocaleDateString('en-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            });
            
            const res = await apiProducaoDiaria.getByDate(unidadeId, dataFormatada)
    
            const year = format(date, "yyyy");
    
            const producoesAnuais = (await apiProducaoDiaria.getByYear(unidadeId, year)).data;

            const indicadoresAnuais = (await apiProducaoDiaria.indicadoresByYear(unidadeId, {
                timeZoneNumber: getClientTimeZoneOffset(),
                data: dataFormatada
              })).data;
              
            setIndicadoresAnuais(indicadoresAnuais);
            setProducaoDiaria(res.data);
            setProducoesAnuais(producoesAnuais)
            setDataEscolhida(date);
        } catch (error) {
            console.error(error.message);
        }
    }, [unidadeId]);
    
    const gerarRelatorio = async (tempSelectedDateDe, tempSelectedDateAte) => {
        try {
            setIsLoadingRelatorio(true);
            const timeZoneOffset = getClientTimeZoneOffset();

            const dataInfo = {
                dataInicio: tempSelectedDateDe,
                dataFim: tempSelectedDateAte,
                TimeZoneNumber: timeZoneOffset
            }

            const dataInicioFormatada = dataInfo.dataInicio.toLocaleString('pt-BR', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				hour12: false 
			}).replaceAll(",", "_").replaceAll(" ", "");

            const dataFinalFormatada = dataInfo.dataFim.toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replaceAll(",", "_").replaceAll(" ", "");

            //se data inicio for maior que data final
            if (dataInfo.dataInicio > dataInfo.dataFim) {
                erro('Data de início precisa ser maior que data final');
                return;
            }

            const response = await apiRelatorio.producaoDiariaExcel(unidade.id, dataInfo);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `Produção_Diária${unidade.nome}_${dataInicioFormatada}-${dataFinalFormatada}.xlsx`);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            erro(error.message);
        } finally {
            setIsLoadingRelatorio(false);
        }
    }

    return (
        <div className="pb-[100px]">
            <DatePickerGetData onSearch={onSearch} gerarRelatorio={gerarRelatorio} loading={isLoadingRelatorio}/>
            <ChartCard chartDataProps={producaoDiaria} chart={ChartBarDefault}/>
            <ChartCard chartDataProps={producoesAnuais} chart={ChartBarAnual} total="producaoTotalMensal" type="mensal"/>
            <ChartCard chartDataProps={producaoDiaria} chart={ChartBarDefault} total="none" typeChart="Indicador"/>
            <ChartCard chartDataProps={indicadoresAnuais} chart={ChartBarAnual} total="none" type="mensal" typeChart="Indicador"/>
            <CadastroDeProduto onSave={onSearch} dataEscolhida={dataEscolhida}/>
            <ProducaoDiariaTable producaoDiaria={producaoDiaria} />
        </div>
    );
}